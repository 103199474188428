import { Button, Text } from '@geist-ui/core';
import React from 'react';
import "../App.css";
import { useLocation, useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get('success');
  let navigate = useNavigate(); 

  return (
    <div className='center'>
        <Text h1>Kindle2Notion</Text>
        <img src="k2n128.png" alt="Logo"/>
        <Button onClick={_ => navigate("terms")} placeholder={undefined} type="secondary" ghost onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Terms & Conditions</Button>
        <Button onClick={_ => navigate("privacy")} placeholder={undefined} type="secondary" ghost onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Privacy policy</Button>
    </div>
  );
};

export default Home;