import { Text } from '@geist-ui/core';
import React from 'react';
import "../App.css";
import { useLocation } from 'react-router-dom';

const Payment: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get('success');

  return (
    <div className="view-container">
      <Text className="main-h" h1>Payment Status</Text>
      <div className="flex-grow-container gray-container margin-bottom"> 
        <div>
          <Text h2 type={success === 'true'  ? 'success' : 'error'}>{success === 'true' ? 'Successful' : 'Unsuccessful'}</Text>
          {success === 'true' ? 
            <Text>The payment was successful. Thank you for your payment! You may now fully access the extension's features.</Text> : 
            <Text>The payment was unsuccessful. Please review your payment information and try again. If the issue persists, contact us for assistance: bitemuse.dev@gmail.com</Text>
          }
        </div>
      </div>
    </div>
  );
};

export default Payment;