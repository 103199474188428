import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import Terms from './components/Terms';
import Payment from './components/Payment';
import "./App.css";
import Home from './components/Home';
import Privacy from './components/Privacy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;