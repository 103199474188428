import { Text } from '@geist-ui/core';
import React from 'react';
import "../App.css";


const Privacy: React.FC = () => {
  return (
    <div className="view-container">
      <Text className="main-h" h1>Privacy policy</Text>
      <div className="flex-grow-container gray-container margin-bottom"> 

<p>Updated at 2024-03-26</p>

    <h2>1. Introduction</h2>
    <p>This Privacy Policy describes our practices regarding the collection, use, and sharing of your information through the use of our extension. By using Kindle2Notion, you consent to the policies and practices outlined in this document.</p>

    <h2>2. Information Collection</h2>
    <p>When you use Kindle2Notion, we collect the following types of information:</p>
    <ul>
        <li><strong>Email Address:</strong> We require your email address to identify your user account and ensure secure access to our services.</li>
        <li><strong>Stripe Payment ID:</strong> We collect your Stripe Payment ID to manage and track payments and subscriptions associated with your account.</li>
        <li><strong>User Feedback:</strong> We collect feedback you provide to improve our extension. Feedback may be displayed on our website in an anonymized form.</li>
    </ul>

    <h2>3. Use of Information</h2>
    <p>The information we collect is used solely for the following purposes:</p>
    <ul>
        <li>To provide, operate, and maintain our extension;</li>
        <li>To improve the usability and functionality of our services;</li>
        <li>To communicate with you, including sending you updates and user support;</li>
        <li>To process transactions and manage user subscriptions efficiently.</li>
    </ul>

    <h2>4. Sharing of Information</h2>
    <p>Your information is not shared with any third parties. It is used exclusively for the operation and functionality of Kindle2Notion. All personal data is processed and stored on secure Google infrastructure, ensuring compliance with applicable data protection regulations.</p>

    <h2>5. Data Storage and Security</h2>
    <p>We implement robust security measures to protect your personal information from unauthorized access, alteration, and disclosure. Data is stored securely on Google's infrastructure, leveraging their advanced security protocols.</p>

    <h2>6. Your Data Rights</h2>
    <p>You have the right to access, correct, or delete your personal information stored by us. You can manage your information by contacting us.</p>

    <h2>7. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

    <h2>8. Contact Us</h2>
    <p>If you have any questions or concerns about our Privacy Policy or data handling practices, please contact us at bitemuse.dev@gmail.com.</p>

    <p>This Privacy Policy ensures that Kindle2Notion is committed to protecting your privacy and handling your personal data transparently and securely.</p>
         
      </div>
    </div>
  );
};

export default Privacy;